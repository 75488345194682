import { Link } from '@remix-run/react'
import { useRef, type ReactNode, useEffect, useState } from 'react'
import { Wordmark } from '~/components'
import clsx from 'clsx'

type Props = {
  className?: string
  children?: ReactNode
  onClickWordmark?: () => void
}

export const Header = ({ className, children, onClickWordmark }: Props) => {
  const ref = useRef<HTMLElement>(null)
  const [stuck, setStuck] = useState(false)

  useEffect(() => {
    const cachedRef = ref.current
    if (!cachedRef) return

    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] },
    )
    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ref])

  return (
    <header
      ref={ref}
      className={clsx('fixed-header app', className, stuck && 'stuck')}
    >
      <div className="header-content">
        <Link to="/" className="logo" onClick={onClickWordmark}>
          <Wordmark />
        </Link>
        {children}
      </div>
    </header>
  )
}
